import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from 'styled-components';

import { bottleFormat } from 'HELPERS/formatter';
import { genUTM }      from 'HELPERS/url';
import { localePrice } from 'HELPERS/price';
import { omit, pick } from 'HELPERS/object';

import Link from 'GLOBAL/Link';
import CardImage from 'COMPONENTS/card/cardimage';
import CardBody from 'COMPONENTS/card/cardbody';
import CardActions from 'COMPONENTS/card/cardactions';

import AppConfig from 'UTILS/AppConfig';

const CardDiv = styled.div`
	${(props) => (props.$isactive) ? `
		visibility: visible;
		opacity: 1;
		transition: opacity 400ms ease-out;
		flex:1;
		` : `
		position: absolute;
		visibility: hidden;
		opacity: 0;
		top:0;
	`}
`;

const Card = ({ lookup, isactive }) => {
	const {
		variant_id,
		variant_year,
		product_name,
		price,
		price_currency,
		format_ml,
		landing_url,
		image_url,
		value,
		...lookupProps
	} = lookup;

	let capture = pick(lookup, ['id','variant_id','variant_year','product_name','price','format_ml','landing_url']);

	return (
		<CardDiv className="irjs__card--inner" $isactive={ isactive }>
			<CardImage
				product={ {
					image_url,
					name: product_name,
					url: genUTM(landing_url, product_name),
					product_id: value,
					variant_id,
				} }
				capture={ {...capture, product_id: value} }
			/>
			<CardBody>
				<h3>
					{landing_url && landing_url.length && (
					<Link href={ genUTM(landing_url, product_name) } title={ product_name } capture={ {...capture, product_id: value} }>
						{product_name}
					</Link>
					)}
					{!landing_url && (
						<span>{product_name}</span>
					)}
				</h3>
			</CardBody>
			<CardActions>
				{(price && landing_url) && (
					<Link
						href={ genUTM(landing_url, product_name) }
						title={ product_name }
						capture={ {...capture, product_id: value} }
						className={ clsx('irjs__btn', AppConfig.getCustomStyling(['results.productCardBtn', 'basicButton'])) }
						role="button"
					>
						{localePrice(price, price_currency, AppConfig.hasDecimalPlaces())}
					</Link>
				)}
				{(price && !landing_url) && (
					<span className={clsx('my-auto')}>{localePrice(price, price_currency, AppConfig.hasDecimalPlaces())}</span>
				)}
			</CardActions>
		</CardDiv>
	);
};
Card.propTypes = {
	lookup: PropTypes.shape(),
	isActive: PropTypes.bool,
};
Card.defaultProps = {
	lookup: null,
	isactive: false,
};

const ProductCard = ({ lookups, compact, hasMultipleLookups }) => {
	const [currentCard, setCard] = useState(0);
	const formatIndicators = hasMultipleLookups ? (
		<>
			<div className="irjs__card--format-opts">
				{}
				<div className="irjs__card--format-opts-wrapper">
					{lookups.map((lookup, idx) => {
						const { format_ml } = lookup;

						return (
							<div
								className={ clsx(
									'irjs__card--format-selector',
									!format_ml && 'irjs__card--format-selector-pearl',
									currentCard === idx && 'irjs__card--format-selector-active'
								) }
								key={ lookup.id }
								onClick={ (e) => {
									if (idx === currentCard) return;
									setCard(idx);
								} }
							>
								{format_ml ? bottleFormat(format_ml) : ''}
							</div>
						);
					})}
				</div>
			</div>
		</>
	) : null;

	return (
		<div className={ clsx('irjs__card--wrapper', compact && 'irjs__card--compact') }>
			<div className={ clsx('irjs__card', 'irjs__card--product', AppConfig.getCustomStyling('results.productCard')) }>
				{lookups.map((lookup, idx) => (
					<Card key={ lookup.id } lookup={ lookup } isactive={ Boolean(currentCard === idx) } />
				))}
				{hasMultipleLookups && <>{formatIndicators}</>}
			</div>
		</div>
	);
};

ProductCard.propTypes = {
	lookups: PropTypes.arrayOf(PropTypes.shape()),
	compact: PropTypes.bool,
	hasMultipleLookups: PropTypes.bool,
};
ProductCard.defaultProps = {
	lookups: null,
	compact: false,
	hasMultipleLookups: false,
};

export default ProductCard;
