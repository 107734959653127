import chainFunction                              from 'HELPERS/createChainedFunction';

import clsx                                       from 'clsx';
import PropTypes                                  from 'prop-types';
import React, {useMemo}                           from 'react';
import { useUncontrolled }                        from 'uncontrollable';
import InputSelector                              from './InputSelector';

const InputSelectorGroup = (props) => {


  const {
    as: Component = 'div',
    fields,
    type,
    name,
    style,
    value,
    disabled,
    defaultValues,
    className,
    onClick,
    onChange,
    ...controlledProps
  } = useUncontrolled(props, Object.assign(props.type === 'radio' ? {
    value: 'onClick',
  } : {}, {}));

  const groupValues = fields.reduce((obj, item) => {
	  const {id} = item;
	  if(type === 'checkbox') {
      obj[id] = false;
      if(defaultValues && Object.keys(defaultValues).length && Object.keys(defaultValues).includes(id)) obj[id] = defaultValues[id];
    }
	  return obj;
  }, {});

  return (
    <Component
      className={ clsx(className) }>
      { fields.length && fields.map((field) => {
        const { value: fieldVal, ...fieldProps } = field;

        const isChecked = () => {
          if(type === 'checkbox') return groupValues[field.id];
          if(type === 'radio') {
            if(typeof value === 'object'){
              if(Object.keys(value).includes('key') && value.key === fieldVal) return true;
              if(Object.keys(value).includes('fieldValue') && value.fieldValue === fieldVal) return true;
            } else {
              return value === fieldVal;
            }
            return false;
          }
        };

        if(!style || !fieldProps.style){
          fieldProps.style = 'group-pill';
        }

        const selectorProps = {
          ...fieldProps,
          type,
          name,
          disabled: (field.disabled) ? field.disabled : disabled,
          checked: (field.checked && field.checked.constructor === Function) ? field.checked() : isChecked(),
          value: field.value,
          onClick: onClick,
        };
        
        return (<InputSelector { ...selectorProps } key={ field.id } />);
      })}
    </Component>
  );
};

InputSelectorGroup.propTypes = {
  as: PropTypes.elementType,
  fields: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  value: PropTypes.any,
  values: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

InputSelectorGroup.defaultProps = {
  onClick: () => {},
  onChange: () => {},
};

export default InputSelectorGroup;
