import React, { useEffect, useContext, useState, useRef, createRef, } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Results from './results';
import AppConfig from 'UTILS/AppConfig';

const RecResults = (props) => {
	const resultsMounted = useRef(true);

	useEffect(
		() => () => {
			if (!resultsMounted.current) {
				resultsMounted.current = false;
				setTimeout(()=> {
					const updateEvent = new CustomEvent('appUnmout_results', { detail: { type: 'results' }} );
	    			document.dispatchEvent(updateEvent);
					props.root?.unmount();
				});
			}
		},
		[resultsMounted.current]
	);

	return (
		<>
		<React.StrictMode>
			{resultsMounted.current && (
				<div
					className={ clsx(
						'irjs__results--container',
						AppConfig.getCustomStyling('results.container'),
						AppConfig.param?.devMode && 'irjs__hidden'
					) }
				>
					<Results onRenderComplete={ props.onRenderComplete } renderProductCards={ props.renderProductCards } />
				</div>
			)}
		</React.StrictMode>
		</>
	);
};

RecResults.propTypes = {};
RecResults.defaultProps = {};

export default React.memo(RecResults);
