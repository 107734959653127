import clsx                                       from 'clsx';

import PropTypes                                  from 'prop-types';
import React, { useEffect, useState }             from 'react';

const noop = () => undefined;

const InputSelector = (props) => {
  const {
    type='checkbox',
	  id,
	  name,
	  label,
	  labelClassName,
	  checked,
	  disabled,
	  value,
	  onClick,
	  onChange,
	  onClickValidate,
	  onClickError,
	  className,
    attr={},
    ...prop
  } = props;

  const [selectorStatus, setSelectorStatus] = useState(props.checked);
  const [selectorEl, setSelectorEl] = useState();

  const [,forceUpdate] = useState();

  const handleClick = (e) => {
    // e.presist();
    if (onClickValidate && onClickValidate.constructor === Function) {
      const isValid = onClickValidate();

      if (isValid) {
        setSelectorStatus(!selectorStatus);
        if(onClick && onClick.constructor === Function) onClick({ key: e.target.id, value: !selectorStatus, fieldValue: e.target.value, label });
      }
      if (!isValid) {
        if (onClickError && onClickError.constructor === Function) onClickError();
      }
    } else {
      setSelectorStatus(!selectorStatus);
      if(onClick && onClick.constructor === Function) onClick({ key: e.target.id, value: !selectorStatus, fieldValue: e.target.value, label });
    }
  };

  useEffect(() => {
    const _input = document.querySelector(`input[id=${props.id}]`);   
    const _type =  _input.getAttribute('type'); 
      if(!props.checked) {
        _input.removeAttribute('checked');
        setSelectorStatus(false);
      }
  }, [props.checked]);


  return (
    <div className={ clsx(
      'irjs__selector', 
      className
      ) }
      {...attr}
    >
      <input
        type={ type }
        name={ name || id }
        id={ id }
        checked={ props.checked }
        onClick={ handleClick }
        onChange={ onChange || noop }
        disabled={ disabled || false }
        value={ value }
        ref={ setSelectorEl }
      />
      <label className={ labelClassName } htmlFor={ id }>
    	  {label.constructor === Function ? label() : (<span>{label}</span>)}
      </label>
    </div>
  );
};

InputSelector.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.any,
  labelClassName: PropTypes.string,
  checked:  PropTypes.oneOfType([PropTypes.bool,PropTypes.shape()]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape()]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onClickValidate: PropTypes.func,
  onClickError: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
InputSelector.defaultProps = {
  type: 'checbox',
  checked: false,
};

export default InputSelector;
