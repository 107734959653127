import { underscore } from 'HELPERS/text';
import mixpanelbrowser from 'mixpanel-browser';

class MixpanelTracking{
  constructor(){
    this.mp = mixpanelbrowser.init(__MIXPANEL__, { 
      batch_requests: true,
      ignore_dnt: true,
      track_pageview: true,
      opt_out_tracking_persistence_type: 'cookie',
    }, 'guidedrec');

    this.group = null;
  }

  track(event, params){
     this.mp.track(`${underscore(event)}`, { ...params });
  }

  setIntegrationGrouping(integration_id, params = {}){
     let _group = this.mp.get_group('integration_id', integration_id);

    if(!_group) {
      this.mp.add_group('integration_id', integration_id);
      this.group = this.mp.get_group('integration_id', integration_id);
      this.group.set_once({"integration_id": integration_id, ...params});
    }
    if(_group) {
      this.group = _group;
      this.group.set_once({"integration_id": integration_id, ...params});
    }
  }

  setGroupProperties(params){
     this.group.set(params);
  }


  identify(integration_id){
    this.mp.identify(integration_id);
  }
}

const mp = new MixpanelTracking();

export { mp as default };